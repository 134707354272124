import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import android from '../assets/google-play-badge.png'
import apple from '../assets/apple-store-badge.svg'

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    badge_apple: {
        width: 200
    },
    badge_android: {
        width: 225
    }
});

export default function InfoView() {
    const classes = useStyles();
    return (

        <Card className={classes.root}>
            <CardContent>
        <Typography variant="body" component="p">
                    Wollen Sie selbst bestimmen, von wem Sie Nachrichten und Angebote bekommen?
        </Typography>
                <br></br>
                <Typography variant="body" component="p">
                    Möchten Sie selbst entscheiden, wer Ihre Daten erhält und was damit geschieht?
        </Typography>
                <br></br>
                <Typography variant="body" component="p">
                    Wünschen Sie sich eine Übersicht darüber, wem Sie Ihre Daten gegeben haben?
        </Typography>
                <br></br>
                <Typography variant="body" component="p">
                    Dann verwenden Sie unsere App!
        </Typography>
                <br></br>
                <a href='https://ogy.de/easyid-android'><img className={classes.badge_android} alt='Jetzt bei Google Play' src={android} /></a> <br></br>
                <a href='https://ogy.de/easyid-apple'><img className={classes.badge_apple} alt='Jetzt im App Store' src={apple} /></a>
            </CardContent>
        </Card>
    );
}