import React from 'react';
import logo from './assets/Icon-1024.png';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import InfoView from './components/InfoView';

function App() {
  return (
    <div className="App">
      <AppBar position="static" color="transparent">
        <Toolbar>
          <img className="App-logo" src={logo} alt="" />
          <div className="App-title"><Typography variant="h6" color="inherit">
            EasyID
              </Typography></div>
        </Toolbar>
      </AppBar>
      <div style={{ 'paddingTop': '50px', 'paddingLeft': '20px', 'paddingRight': '20px', 'paddingBottom': '50px' }}>
        <InfoView></InfoView>
      </div>
      <Divider />
      <div style={{'paddingTop': '20px'}}>
      <a className="Impressum-Link" href='https://datenschutz-easyid.r3think.io/'>Impressum & Datenschutz</a>
      <br></br><br></br>
      </div>
    </div>
  );
}

export default App;
